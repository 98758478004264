<!-- Complaint -->
<template>
  <div class="complaint-detail">
    <div class="content">
      <div class="item">
        <div class="title">投诉原因</div>
        <Field v-model="reason" readonly />
      </div>
      <div class="item">
        <div class="title">投诉描述</div>
        <Field
          v-model="desc"
          rows="4"
          autosize
          type="textarea"
          placeholder="请填写投诉描述"
        />
      </div>
      <div class="item">
        <div class="title">活动截图</div>
        <div class="img-wrap">
          <Uploader
            v-model="fileList"
            upload-icon="plus"
            :after-read="afterRead"
          />
        </div>
      </div>
      <div class="item">
        <div class="title">联系电话</div>
        <Field v-model="phone" placeholder="请填写您的联系方式，以便联系" />
      </div>
    </div>

    <Button type="info" @click="submit">提交</Button>
    <Button class="prev" type="info" @click="prevBtn">上一步</Button>
  </div>
</template>

<script>
import { Field, Button, Uploader, Toast } from 'vant'
import OSS from 'ali-oss';
export default {
  components: { Field, Button, Uploader },
  data() {
    return {
      checked: this.$route.query.checked,
      list: [
        {
          id: 1,
          name: '虚假活动，活动不真实'
        }, {
          id: 2,
          name: '报名后无法联系商家'
        }, {
          id: 3,
          name: '商家拒绝退款'
        }, {
          id: 4,
          name: '页面内涵政治敏感、色情低俗、欺诈、违法、暴力'
        }, {
          id: 5,
          name: '页面内涵造谣、传谣'
        }, {
          id: 6,
          name: '页面内涵侵权、抄袭'
        }, {
          id: 7,
          name: '页面内涵诱导分享'
        }, {
          id: 8,
          name: '其他'
        },
      ],
      desc: '',
      fileList: [],
      imgList: [],
      phone: '',
      client: new OSS({
        region: 'oss-cn-beijing',
        accessKeyId: 'LTAI4FqCLakzf7Y7djy4ZAAL',
        accessKeySecret: 'zar1lbB808aGRHf5VHSZYqEFVnq55B',
        bucket: 'epintuan',
      })
    }


  },
  computed: {
    reason() {
      return this.list.find(it => it.id === Number(this.$route.query.checked)).name
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    afterRead(file) {
      console.log(file, 'adaa')
      this.client.put(file.file.name + '-' + new Date().getTime(), file.file)
        .then(res => {
          console.log(res, 'adaa')
          // file.url = res.url
          this.imgList.push(res.url)
          // resolve(res);
        });
    },

    submit() {
      const params = {
        event_id: this.$route.query.event_id,
        class_id: this.checked,
        class_name: this.list.find(it => it.id === Number(this.checked)).name,
        content: this.desc,
        images: this.imgList.join(','),
        telphone: this.phone
      }
      // 
      this.$axios.post("/Feedback/addFeedback", params)
        .then(res => {
          console.log(res)
          Toast('提交成功');
          this.$router.go(-2)
        }).catch(error => {
          Toast(error);
        });
    },
    prevBtn() {
      this.$router.go(-1)
    },
  }
}
</script>
<style lang="scss">
.complaint-detail {
  height: 100vh;
  background: #f5f5f5;
  .title {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    background: #f5f5f5;
  }

  .item {
    background: #fff;
  }
  .img-wrap {
    padding: 10px 20px;
  }
  // .van-uploader {
  //   width: 100%;
  // }
  .van-uploader__preview-image {
    width: 75px;
    height: 75px;
  }
  .van-uploader__upload {
    width: 75px;
    height: 75px;
    // margin: 0 auto;
    border: 1px dashed #999;
    background: #fff;
    // width: 92%;
  }

  .van-button {
    display: block;
    margin: 30px auto 0;
    width: 92%;
  }

  .prev {
    margin-top: 10px;
  }
}
</style>